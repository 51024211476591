.services {
  margin-top: 50px;
}
.services > h1 {
  text-align: center;
  margin: 0 auto;
  font-size: 29px;
  font-weight: 800 !important;
  max-width: 120px;
  background-image: linear-gradient(120deg, #678bd8 0%, #8fd3f4 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 95%;
  transition: all 0.25s ease-in;
}

.services > h1:hover {
  background-size: 100% 88%;
  color: white;
  cursor: pointer;
}
.services > hr {
  max-width: 80px;
  margin: 10px auto;
}
.services__Info {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}

.services__First > img {
  /* height: 280px; */
  max-width: 340px;
  height: 280px;
  object-fit: cover;
  box-shadow: rgba(199, 197, 197, 0.856) 8px 8px;
}

.services__First {
  text-align: center;
  margin-top: 70px;
}

.services__First > h1 {
  color: #0f74ba;
  margin-top: 20px;
  font-size: 24px;
}

.services__First > p {
  margin-top: 20px;
  font-size: 22px;
  width: 370px;
}

@media screen and (max-width: 1200px) {
  .services__Info {
    flex-direction: column;
  }
  .services__First > p {
    margin: 0 auto;
  }
}
