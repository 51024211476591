.header {
  background: url("../Assest/Hero.jpg");
  height: 60vh;
  background-size: cover;
  background-position: calc(100%) calc(25%);
}

.about___H1 {
  max-width: 240px !important;
  font-size: 29px;
  font-weight: 800;
}

.about > h1 {
  margin-bottom: 70px;
  font-size: 29px !important;
}

.about__Paragraph {
  max-width: none !important;
}
.about__Info {
  margin-top: 80px;
  display: flex;
  justify-content: space-around;
}

.about__Title {
  font-size: 25px !important;
}
.about__InfoDetails {
  margin-left: -100px;
  text-align: center;
}

.about__InfoDetails > img {
  margin: 20px 0;
  min-height: 160px;
  max-width: 160px;
}

.about__InfoDetails > h2 {
  color: #0f74ba;
}
.about__InfoDetails > p {
  margin-top: 20px;
  max-width: 300px;
}

.about__build {
  text-align: center;
}
.about__build > h1 {
  font-size: 29px;
  font-weight: 600;
  color: #0f74ba;
}
.about__build > button {
  margin: 40px;
  padding: 10px 20px;
  outline: none;
  border: none;
  font-size: 25px;
  background: #0f74ba;
  color: white;
}

.about__Why > span > a {
  color: black;
  text-decoration: none;
}

.about__Why > span > a::after {
  color: black;
  text-decoration: none;
}

.about__Team {
  text-align: center;
}
.about__Team > h1 {
  margin: 20px auto;
  max-width: 300px;
  background-image: linear-gradient(120deg, #678bd8 0%, #8fd3f4 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 90%;
  transition: background-size 0.25s ease-in;
  font-size: 28px;
}

.about__Team__h1 {
  margin: 20px auto;
  max-width: 280px;
  background-image: linear-gradient(120deg, #678bd8 0%, #8fd3f4 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 90%;
  transition: background-size 0.25s ease-in;
  font-size: 28px;
}
.about__Team > h1:hover {
  background-size: 100% 88%;
  color: white;
}
.about__TeamInfo {
  margin: 60px 0;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}
.about__TeamInfoDetails {
  text-align: center;
}

.about__TeamInfoDetailsStyle {
  margin: 20px 0;

  margin: 20px auto;
  max-width: 280px;
  background-image: linear-gradient(120deg, #678bd8 0%, #8fd3f4 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 90%;
  transition: background-size 0.25s ease-in;
  font-size: 28px;
}

.about__TeamInfoDetailsStyle:hover {
  background-size: 100% 88%;
  color: white;
}
.about__TeamInfoDetails > h2 {
  color: #0f74ba;
  margin: 15px 0;
}
.about__TeamInfoDetails > p {
  font-size: 18px;
  margin: 20px auto;
  font-size: 20px;
  text-align: left;
  max-width: 1000px;
  line-height: 1.8;
}

.about__TeamInfoDetails > img {
  margin-top: 30px;
  height: 220px;
  width: 220px;
  border-radius: 50%;
  object-fit: cover;
}

.about__Paragraph > p {
  max-width: 1000px;
  margin: 0 auto;
  text-align: left;
}

.about__Paragraph > h2 {
  margin: 20px 0;
  color: #0f74ba;
}
.about-Link {
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 1000px) {
  .about__Info {
    flex-direction: column;
  }
  .about__InfoDetails {
    margin-left: 0 !important;
  }
  .about__InfoDetails > p {
    text-align: center;
    margin: 0 auto;
  }
  .about__TeamInfoDetails > p {
    text-align: center;
    max-width: 577px;
  }
}
