.hero {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.6) 100%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%), no-repeat,
    url("https://i.ibb.co/PQWyvcF/WE-CAN-WIN-Oct-2018.jpg") no-repeat;
  background-position: calc(100%) calc(21%);
  background-size: cover;
  height: 40rem;
}

.hero__Left {
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.hero__Left > img {
}
.hero__Left > h1 {
  color: white;
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 80px;
}
.hero__Left > h1 > hr {
  width: 200px;
  margin: 5px auto;
  margin-bottom: -30px;
}
.hero__Left > img {
  max-width: 800px;
  height: 50px;
  box-shadow: 0 2px 3rem rgb(0 0 0 / 30%);
}

.hero__Left > button {
  margin-top: -20px;
  padding: 15px 25px;
  font-size: 20px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  background: rgb(45, 101, 221);
  box-shadow: 0 2px 3rem rgb(0 0 0 / 100%);

  border: none;
}

.hero__Right {
}
