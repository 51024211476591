.workshop {
  width: 90%;
  max-width: 980px;
  margin: auto;
}
.workshop__Hero {
  background: url("../Assest/WCW3.jpeg");
  height: 60vh;
  background-size: cover;
  background-position: calc(100%) calc(25%);
}
.course {
}

.workshop > h1 {
  text-align: start;
  margin: 40px 0;
  position: relative;
}

.workshop > h1::before {
  content: "";
  background: #0f74ba;
  width: 50px;
  height: 2px;
  position: absolute;
  bottom: -5px;
}

.workshop__info {
  /* text-align: center; */
  color: black;
}
.workshop__info > ul {
  margin-left: 45px;
}

.workshop__info > ul > li {
  margin: 10px 0;
}

.workshop__Info__More {
  line-height: 1.8;
}

.workshop__Info__Plan {
  margin: 50px 0;
  margin-left: 37px;
  color: black;
}
.workshop__Info__Plan > ul {
}
.workshop__Info__Plan > ul > li {
  margin: 10px 0;
}

.worksop__Hero {
  height: 50vh;
  background-size: cover;
  background-image: url("");
}

.worksop__Hero > h1 {
  text-align: center;
}

/* 
linear-gradient( 
180deg
, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.6) 100% ), linear-gradient(
180deg
, rgba(0, 0, 0, 0.2) 0%, transparent 100%), no-repeat, url("") no-repeat; */
