nav {
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  margin-bottom: 15px;
}
nav > img {
  width: 250px;
}

nav > ul {
  cursor: pointer;
  font-size: 22px;
  align-self: center;
  list-style: none;
}

nav > ul > li {
  display: inline;
  margin: 15px;
}

nav > ul > li:hover {
  color: red;
}

.nav-link {
  text-decoration: none;
  color: black;
}

@media screen and (max-width: 730px) {
  nav {
    flex-direction: column;
    align-items: center;
  }
  nav > ul {
    font-size: 15px;
    text-align: center;
  }
}
