footer {
  margin-top: 70px;
  height: 180px;
  background: #1f2325;
  color: white;
  padding: 10px;
}
.footer__Container {
}
footer > h1 {
  margin: 15px 0 15px 0;
  text-align: center;
}
.footer__Icons {
  display: flex;
  justify-content: center;
}
.footer__Icons > a > img {
  border-radius: 50%;
  margin: 10px;
  height: 40px;
}

.footer__Links {
  margin-top: 5px;
  display: flex;
  justify-content: center;
}

.NavLink {
  text-decoration: none;
  color: inherit;
}
.NavLink > h1 {
  margin: 8px;
  font-size: 18px;
}
.footer__Created > h1 {
  margin: 15px 0 15px 0;
  font-size: 18px;
  text-align: center;
}
