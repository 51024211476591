.Search__result {
  width: 90%;
  max-width: 1100px;
  margin: auto;
}

.back {
  display: flex;
  float: left;
  padding: 5px;
  border-radius: 5px;
  margin-left: 25px;
  opacity: 1;
  color: #5091f9;
  text-decoration: none;
}
.back > h3 {
  margin-top: -3px;
}
.searchResult {
  display: flex;
  position: relative;
  margin: 20px;
  padding: 20px;
  border-bottom: 1px solid lightgray;
  cursor: pointer;
}

.searchResult:hover {
  opacity: 0.8;
}

.search__images {
  height: 200px;
  width: 350px;
  border-radius: 10px;
  overflow: hidden;
}

.searchResult__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 20px;
}

.searchResult__heart {
  position: absolute;
  top: 20px;
  right: 40px;
}

.searchResult__infoTop {
  width: 40vw;
}

.searchResult__infoTop > h3 {
  font-weight: 300;
  margin-top: 10px;
}

.searchResult__infoTop > p {
  margin-top: 10px;
  font-size: 13px;
  color: gray;
}

.searchResult__infoBottom {
  display: flex;
  justify-content: space-between;
}

.searchResult__star {
  color: #5091f9;
}

.searchResult__stars {
  display: flex;
  align-items: center;
}

.searchResults__price > p {
  text-align: right;
}

.searchResults__price {
  position: absolute;
  bottom: 20px;
  right: 30px;
}

.SearchInformation {
  width: 90%;
  max-width: 1100px;
  margin: auto;
}
.SearchInformation__location {
  display: flex;
  justify-content: space-between;
}

.SearchInformation__Left {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.SearchInformation__right {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.h2 {
  font-size: 25px;
  font-weight: 300;
  margin: 10px;
}

.h2 span {
  font-size: 30px;
}

p {
  font-size: 20px;
}

.SearchInformation__images {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}

.SearchInformation__images > div {
  cursor: pointer;
  height: 230px;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: whitesmoke;
  transition: all 0.5s ease-in;
}

.SearchInformation__images > div:hover {
  opacity: 0.7;
  transform: scale(0.98);
}

.SearchInformation__images > div:nth-of-type(1) {
  grid-column: 1 / 3;
}

.SearchInformation__images > div:nth-of-type(6) {
  grid-column: 3 / 5;
}

.SearchInformation__images > div:nth-of-type(9) {
  grid-column: 3 / 5;
}

.SearchInformation__images > div:nth-of-type(10) {
  grid-column: 1 / 3;
}

.Search__info {
  margin-top: 40px;
  height: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
}
.Search__info div {
  height: 30%;
}
.two {
  width: 40%;
}
.Search__info .Search__info__left,
.Search__info .two {
  height: 45%;
}
.left {
  width: 60%;
}
.Search__info__left {
  display: flex;
  justify-content: space-between;
}
.Search__info__left > img {
  border-radius: 50%;
  height: 50px;
}
.Search__info__container > h3 {
  font-weight: 400;
}

h1 {
  font-weight: 400;
  font-size: 24px;
}

.Search__info__left > h3 {
  border-bottom: 1px solid #333;
}

.entire_home {
  display: flex;
  gap: 0.5rem;
}

.entie_home_text {
  font-size: 0.8rem;
  font-weight: 400;
}
.entie_home_text > p {
  font-size: 1rem;
}

.home {
  margin-top: 20px;
  margin-bottom: -46px;
}

hr {
  opacity: 0.5;
  color: lightgrey;
}
.search__hr {
  margin-top: -35px;
}
.home__hr {
  margin-top: 36px;
}

.covid__19 > p {
  padding-top: 20px;
  font-size: 19px;
}
.covid__19 > button {
  outline: none;
  border: none;
  background-color: #ff6167;
  color: white;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 25px;
}

.covid__19__contct {
  border-bottom: 1px solid #333;
  max-width: 120px;
  cursor: pointer;
}

.sleeping__container {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  text-align: center;
}
.sleeping__container__border {
  border: 1px solid #333;
}
.sleeping {
  margin-top: -70px;
}

.three__container {
  border: 1px solid rgba(61, 59, 59, 0.13);
  padding-bottom: 44vh;
  text-align: center;
}
.three__container > h4 > span {
  color: #ff6167;
}
.three__container > h4 {
  margin-top: 20px;
}
.three__container > img {
  border-radius: 50%;
  height: 70px;
  padding: 20px;
}
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}
form > input {
  margin: 10px;
  max-width: 100%;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.13);

  box-sizing: border-box;
}
.contact__btn {
  background-color: #ff6167;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 20px;
}
.emoji {
  color: #ff6167;
}

.searchPage__info {
  padding: 20px;

  width: 90%;
  max-width: 1100px;
  margin: auto;
}

.searchPage__info > p {
  margin-bottom: 10px;
  font-size: 14px;
}

.searchPage__info > h1 {
  margin-bottom: 30px;
}

.searchPage__info > button {
  border-radius: 30px;
  text-transform: inherit;
  margin: 5px;
}

.Link {
  text-decoration: none !important;
}
.Link:visited {
  color: black;
}

.service {
  max-width: 1000px;
  margin: 30px auto;
  display: grid;
  grid-template-rows: [header-start] 100px [header-end box-start] 200px [box-end main-start] 250px [main-end footer-start] 100px [footer-end];
  grid-template-columns: repeat(3, [col-start] 1fr [col-end]) 200px [grid-end];
  grid-gap: 30px;
}
.service > * {
  color: black;
  font-size: 22px;
}
.service .header {
  align-self: end;
  grid-column: col-start 1 / grid-end;
}
.service .sidebar {
  grid-column: col-end 3 / grid-end;
  grid-row: box-start / main-end;
}
.service .main-content {
  grid-column: col-start 1 / col-end 3;
}
.service .footer {
  grid-column: col-start 1 / grid-end;
}
.small__image {
  height: 220px;
  width: 220px;
}
.sidebar__container {
  position: relative;
  display: block;
  box-sizing: border-box;
  width: 300px;
  margin: 50px auto;
  padding: 20px;
  height: 400px;
  border-radius: 5px;
  background: #fdfefe;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.4s ease-in-out;
}
.sidebar__container:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.sidebar__title {
  display: inline-block;
  text-transform: uppercase;
  font-size: 18px;
  padding-bottom: 10px;
  padding-right: 20px;
  color: black;
  border-bottom: 1px solid #423c33;
}

.sidebar__news {
  overflow-y: scroll;
  height: 270px;
  margin-top: 10px;
  padding-right: 10px;
}
.sidebar__item {
  font-size: 19px;
  margin-bottom: 16px;
}
.sidebar__item:last-child {
  margin-bottom: 0;
}
.sidebar__item > p:first-child {
  margin: 5px 0;
}

.sidebar__shares {
  font-family: "Roboto Light", sans-serif;
  color: #dfdfdf;
  font-size: 12px;
  margin: 0;
  cursor: pointer;
}

.sidebar__shares:hover {
  color: #423c33;
}
.coloring {
  color: #5091f9;
  font-weight: 800;
  font-size: 18px;
}

.icon-trending {
  margin-top: -3px;

  margin-right: 5px;
  fill: #5091f9;
  vertical-align: middle;
}
.underline {
  width: 40px;
  margin-top: 5px;
  color: #5091f9;
  border: 1px solid;
}
.underline__middle {
  opacity: 0.5;
  width: 80px;
  margin: 0 auto;
  margin-bottom: 40px;
  margin-top: 20px;
  color: lightgray;
  border: 1px solid;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
}

@media screen and (max-width: 1100px) {
  .searchPage__info {
    text-align: center;
  }
  .searchResult__infoTop {
    width: 40vh;
  }
  .searchResult {
    flex-direction: column;
    align-items: center;
  }
  .searchResult__heart {
    display: none !important;
  }
  .searchResults__price {
    position: static;
  }
  .searchResult__infoBottom {
    flex-direction: column;
  }
  .Search__Button {
    padding: 220px;
  }
  .searchResults__price > p {
    text-align: left;
  }
  .h2 {
    font-size: 24px;
    margin: 5px 0 5px 0;
  }
  .search__images {
    width: 250px;
  }
  .searchResult__infoTop > p {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .underline {
    margin: 0 auto;
  }
  .service {
    display: block !important;
    text-align: center;
  }
}

@media screen and (max-width: 1100px) {
}

.course__Title {
  text-align: center;
  margin: 50px auto;
  font-size: 25px;
  max-width: 110px;
  background-image: linear-gradient(120deg, #678bd8 0%, #8fd3f4 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 90%;
  transition: background-size 0.25s ease-in;
}

.course__Title:hover {
  background-size: 100% 88%;
  color: white;
}

/* Courses Page */
.heroCourses {
  justify-content: space-around;
  background-size: contain;
  background-position-y: center;
  background-position-x: 5rem;
  background-attachment: fixed;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 1) 100%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%),
    url("https://images.unsplash.com/photo-1515343480029-43cdfe6b6aae?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1100&q=80");
  height: 500px;
}

.heroCourses__Left {
  max-width: 400px;
  color: white;

  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  max-height: 100%;
  padding: 0px 2rem;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
}

.heroCourses__Left > h1 {
  font-size: 24px;
}
.heroCourses__Left > p {
  margin: 10px 0;
}

.heroCourses__Left > button {
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #0f74ba;
  padding: 10px 15px;
  border-radius: 10px;
}
.heroCourses__right > img {
  height: 400px;
}

.coursesSection {
  margin-top: 20px;
}
.coursesSection > h1 {
  text-align: center;
  margin: 0 auto;
  font-size: 25px;
  max-width: 110px;
  background-image: linear-gradient(120deg, #678bd8 0%, #8fd3f4 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 90%;
  transition: background-size 0.25s ease-in;
}

.coursesSection > h1:hover {
  background-size: 100% 88%;
}

.coursesSection__ListItem {
  margin: 60px 0 60px 0;
  display: flex;
  justify-content: space-evenly;
}

hr {
  opacity: 0.5;
  max-width: 700px;
  margin: 30px auto;
}
.coursesSection__ListItem > img {
  align-self: center;
  height: 100px;
  width: 200px;
}

.coursesSection__ListItem__Desc > h1 {
  font-size: 20px;
}

.coursesSection__ListItem__Desc > p {
  max-width: 400px;
  font-size: 15px;
  margin-top: 5px;
}

.coursesSection__ListItem__Desc > p > strong {
  color: red;
}
.CoursesSection__ListItem__Price {
  margin: 10px 0 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.link {
  padding: 8px 13px;
  border: none;
  font-size: 18px;
  color: white;
  cursor: pointer;
  background-color: #0f74ba;
  outline: none;
  cursor: pointer;
}

.course__Link {
  text-decoration: none;
  color: white;
}

.course__Container {
  display: flex;
  flex-direction: column;
}

.course {
}
.course__Row {
  margin-bottom: 50px;
}
.course__Hero {
  text-align: center;
  color: white;
  min-height: 400px;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.6) 100%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%), no-repeat,
    url("../Assest//course-Hero.jpg") no-repeat;
  background-position: calc(100%) calc(25%);
  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.course__Hero > h1 {
  font-size: 26px;
  padding: 10px;
}

.course__Hero > h2 {
  font-size: 20px;
}

.course__Hero > hr {
  margin: 20px auto;
  max-width: 300px;
}
.course__Hero > button {
  color: white;
  background-color: #0f74ba;
  border: none;
  font-size: 24px;
  outline: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 20px;
}
.course__title {
  margin-top: 70px;
}

.course__title > h1 {
  margin: 40px auto;
  text-align: center;
  font-size: 29px;
  max-width: 340px;
  background-image: linear-gradient(120deg, #678bd8 0%, #8fd3f4 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 90%;
  transition: background-size 0.25s ease-in;
}
.course__title > p {
  /* text-align: center; */
  color: rgb(87, 86, 86);
  max-width: 1000px;
  line-height: 1.6;
  margin: 20px auto;
}

.hero__HR {
  color: white !important;
  opacity: 1 !important;
  width: 300px;
}

.parent {
  text-align: center;
}
.parent > p {
  text-align: left;
}
.parent > ul {
  display: inline-block;
  text-align: start;
  font-size: 20px;
  padding-left: 2rem;
}

.parents {
  text-align: center;
}

.powerPoint__About {
  display: flex;
  justify-content: space-evenly;
}

.parents > ul {
  line-height: 2;
  display: inline-block;
  max-width: 600px;
  text-align: start;
}

.powerPoint__h1 {
  font-size: 18px;
  margin: 5px 0;
  color: grey;
  margin-left: -20px;
}

.parent > ul > li {
  color: grey;
  margin-top: 10px;
}

.business__Hero {
  max-width: 1000px;
  height: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.business__Img {
  margin: 40px 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.business__title {
  font-size: 24px;
  font-weight: 800px;
}

.management__H3 {
  text-align: center;
  margin: 50px auto;
  font-size: 25px;
}

.leadership__qoutation {
  text-align: center;
}

.leadership__qoutationStrong {
  color: #0f74ba;
}

.contact__Courses {
  margin: 0 auto;
}

@media screen and (max-width: 630px) {
  .coursesSection__ListItem {
    flex-direction: column;
  }

  .coursesSection__ListItem__Desc > p,
  h1 {
    margin: 10px 0;
    max-width: none;
    text-align: center;
  }
  .CoursesSection__ListItem__Price > button {
    width: 100px;
    margin: 0 auto;
  }
}
