.about {
  text-align: center;
  margin: 100px;
}

.about > hr {
  margin: 10px auto;
  width: 100px;
}
.about > p {
  margin: 20px auto;
  max-width: 1100px;
  text-align: left;
  line-height: 1.6;
  opacity: 0.9;
  font-size: 22px;
}

.about > h1 {
  font-size: 32px !important;
  font-weight: 800;
  margin: 0 auto;
  max-width: 190px;
  background-image: linear-gradient(120deg, #678bd8 0%, #8fd3f4 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 90%;
  transition: background-size 0.25s ease-in;
}

.about > h1:hover {
  background-size: 100% 88%;
}

.about__Why {
  margin: 40px 0 !important;
}

.about > h2 {
  margin: 20px 0;
  font-size: 22px;
  font-weight: 200;
}

.about > h2 > span {
  font-weight: 800;
  border-bottom: 1px solid black;
}
.about > img {
  border-radius: 50%;
  margin-top: 15px;
  height: 150px;
}

.about__Title {
  text-align: left;
  font-weight: 600 !important;
}
