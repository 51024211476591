.assest {
  background-image: url("https://images.pexels.com/photos/4709290/pexels-photo-4709290.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");

  color: white;
  background-position-x: 0;
  background-position-y: 75%;
  background-size: cover;
  font-family: "Quicksand", sans-serif;
}

.assest__Container {
  display: flex;
  justify-content: space-evenly;
  min-height: 50vh;

  align-items: center;
}
.assestances {
  text-align: center;
  box-shadow: 0 2px 3rem rgb(0 0 0 / 10%);
}

.assestances > p {
  max-width: 300px;
  font-size: 25px;
}
.assestances > hr {
  width: 70px;
  margin: 10px auto;
}

.assestances > h1 {
  margin-top: 20px;
  font-size: 20px;
}

@media screen and (max-width: 980px) {
  .assest__Container {
    flex-direction: column;
    height: 100vh;
    align-items: center;
  }
}
