@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header {
  background: url(/static/media/Hero.66473fca.jpg);
  height: 60vh;
  background-size: cover;
  background-position: calc(100%) calc(25%);
}

.about___H1 {
  max-width: 240px !important;
  font-size: 29px;
  font-weight: 800;
}

.about > h1 {
  margin-bottom: 70px;
  font-size: 29px !important;
}

.about__Paragraph {
  max-width: none !important;
}
.about__Info {
  margin-top: 80px;
  display: flex;
  justify-content: space-around;
}

.about__Title {
  font-size: 25px !important;
}
.about__InfoDetails {
  margin-left: -100px;
  text-align: center;
}

.about__InfoDetails > img {
  margin: 20px 0;
  min-height: 160px;
  max-width: 160px;
}

.about__InfoDetails > h2 {
  color: #0f74ba;
}
.about__InfoDetails > p {
  margin-top: 20px;
  max-width: 300px;
}

.about__build {
  text-align: center;
}
.about__build > h1 {
  font-size: 29px;
  font-weight: 600;
  color: #0f74ba;
}
.about__build > button {
  margin: 40px;
  padding: 10px 20px;
  outline: none;
  border: none;
  font-size: 25px;
  background: #0f74ba;
  color: white;
}

.about__Why > span > a {
  color: black;
  text-decoration: none;
}

.about__Why > span > a::after {
  color: black;
  text-decoration: none;
}

.about__Team {
  text-align: center;
}
.about__Team > h1 {
  margin: 20px auto;
  max-width: 300px;
  background-image: linear-gradient(120deg, #678bd8 0%, #8fd3f4 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 90%;
  transition: background-size 0.25s ease-in;
  font-size: 28px;
}

.about__Team__h1 {
  margin: 20px auto;
  max-width: 280px;
  background-image: linear-gradient(120deg, #678bd8 0%, #8fd3f4 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 90%;
  transition: background-size 0.25s ease-in;
  font-size: 28px;
}
.about__Team > h1:hover {
  background-size: 100% 88%;
  color: white;
}
.about__TeamInfo {
  margin: 60px 0;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}
.about__TeamInfoDetails {
  text-align: center;
}

.about__TeamInfoDetailsStyle {
  margin: 20px 0;

  margin: 20px auto;
  max-width: 280px;
  background-image: linear-gradient(120deg, #678bd8 0%, #8fd3f4 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 90%;
  transition: background-size 0.25s ease-in;
  font-size: 28px;
}

.about__TeamInfoDetailsStyle:hover {
  background-size: 100% 88%;
  color: white;
}
.about__TeamInfoDetails > h2 {
  color: #0f74ba;
  margin: 15px 0;
}
.about__TeamInfoDetails > p {
  font-size: 18px;
  margin: 20px auto;
  font-size: 20px;
  text-align: left;
  max-width: 1000px;
  line-height: 1.8;
}

.about__TeamInfoDetails > img {
  margin-top: 30px;
  height: 220px;
  width: 220px;
  border-radius: 50%;
  object-fit: cover;
}

.about__Paragraph > p {
  max-width: 1000px;
  margin: 0 auto;
  text-align: left;
}

.about__Paragraph > h2 {
  margin: 20px 0;
  color: #0f74ba;
}
.about-Link {
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 1000px) {
  .about__Info {
    flex-direction: column;
  }
  .about__InfoDetails {
    margin-left: 0 !important;
  }
  .about__InfoDetails > p {
    text-align: center;
    margin: 0 auto;
  }
  .about__TeamInfoDetails > p {
    text-align: center;
    max-width: 577px;
  }
}

.about {
  text-align: center;
  margin: 100px;
}

.about > hr {
  margin: 10px auto;
  width: 100px;
}
.about > p {
  margin: 20px auto;
  max-width: 1100px;
  text-align: left;
  line-height: 1.6;
  opacity: 0.9;
  font-size: 22px;
}

.about > h1 {
  font-size: 32px !important;
  font-weight: 800;
  margin: 0 auto;
  max-width: 190px;
  background-image: linear-gradient(120deg, #678bd8 0%, #8fd3f4 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 90%;
  transition: background-size 0.25s ease-in;
}

.about > h1:hover {
  background-size: 100% 88%;
}

.about__Why {
  margin: 40px 0 !important;
}

.about > h2 {
  margin: 20px 0;
  font-size: 22px;
  font-weight: 200;
}

.about > h2 > span {
  font-weight: 800;
  border-bottom: 1px solid black;
}
.about > img {
  border-radius: 50%;
  margin-top: 15px;
  height: 150px;
}

.about__Title {
  text-align: left;
  font-weight: 600 !important;
}

.assest {
  background-image: url("https://images.pexels.com/photos/4709290/pexels-photo-4709290.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940");

  color: white;
  background-position-x: 0;
  background-position-y: 75%;
  background-size: cover;
  font-family: "Quicksand", sans-serif;
}

.assest__Container {
  display: flex;
  justify-content: space-evenly;
  min-height: 50vh;

  align-items: center;
}
.assestances {
  text-align: center;
  box-shadow: 0 2px 3rem rgb(0 0 0 / 10%);
}

.assestances > p {
  max-width: 300px;
  font-size: 25px;
}
.assestances > hr {
  width: 70px;
  margin: 10px auto;
}

.assestances > h1 {
  margin-top: 20px;
  font-size: 20px;
}

@media screen and (max-width: 980px) {
  .assest__Container {
    flex-direction: column;
    height: 100vh;
    align-items: center;
  }
}

.contact-section {
  /* height: 40rem;
  background: url("https://images.unsplash.com/photo-1499159058454-75067059248a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1051&q=80");
  height: 650px; */
}
.contact-section h1 {
  text-align: center;
  color: white;
}

form {
  width: 343px;
  max-height: 600px;
  padding: 30px;
  box-shadow: rgb(0 0 0 / 50%) 0px 3px 3rem;
  overflow: hidden;
  border-radius: 5px;
  display: grid;
  background: white;
}

.form__Btn {
  align-items: center;
  margin-top: 20px;
  font-size: 1.2rem;
  padding: 1rem;
  border: none;
  background: #0f74ba;
  color: rgb(255, 255, 255);
  transition: all 0.2s ease-out 0s;
}
.contact-section > h1 {
  text-align: center;
  margin: 0 auto;
  font-size: 29px;
  max-width: 170px;
  color: black;
  background-image: linear-gradient(120deg, #678bd8 0%, #8fd3f4 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 95%;
  transition: all 0.25s ease-in;
}

.contact-section > h1:hover {
  background-size: 100% 88%;
  color: white;
  cursor: pointer;
}

input {
  padding: 5px;
  outline: none;
  border-radius: 5px;
  border: none;
  border-bottom: 2px solid grey;
  margin-bottom: 10px;
  letter-spacing: 1px;
  transition: all 0.2s ease-out;

  border-top: none;
  border-right: none;
  border-left: none;
  border-image: none;
  border-image: initial;
  border-bottom: 2px solid grey;
}

.contact__Container {
  display: flex;
  justify-content: space-evenly;
  padding: 50px;
  letter-spacing: 1px;
}

form > textarea {
  padding: 10px;
  margin: 10px auto;
  height: 112px;
  width: 284px;
  border: 0.5px solid grey;
  border-radius: 3px;
  outline: none;
  letter-spacing: 1px;
}

iframe {
  box-shadow: 0 2px 3rem rgba(0, 0, 0, 0.377);
  width: 600px;
  height: 400px;
  border: none;
}

.map__Bottom {
  margin-top: 20px;
  padding: 15px;
  text-align-last: start;
  box-shadow: rgb(0 0 0 / 25%) 0px 1px 1rem;
}
.map__Bottom > h1 {
  margin: 5px 0;
  font-size: 22px;
  color: rgb(110, 110, 110);
}

@media screen and (max-width: 1200px) {
  .contact__Container {
    flex-direction: column;
    align-items: center;
  }
  .map__Bottom {
    box-shadow: none;
  }
  .map__Bottom > h1 {
    text-align-last: center;
  }
}

@media screen and (max-width: 600px) {
  iframe {
    width: 400px;
  }
}

.hero {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.6) 100%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%), no-repeat,
    url("https://i.ibb.co/PQWyvcF/WE-CAN-WIN-Oct-2018.jpg") no-repeat;
  background-position: calc(100%) calc(21%);
  background-size: cover;
  height: 40rem;
}

.hero__Left {
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.hero__Left > img {
}
.hero__Left > h1 {
  color: white;
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 80px;
}
.hero__Left > h1 > hr {
  width: 200px;
  margin: 5px auto;
  margin-bottom: -30px;
}
.hero__Left > img {
  max-width: 800px;
  height: 50px;
  box-shadow: 0 2px 3rem rgb(0 0 0 / 30%);
}

.hero__Left > button {
  margin-top: -20px;
  padding: 15px 25px;
  font-size: 20px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  background: rgb(45, 101, 221);
  box-shadow: 0 2px 3rem rgb(0 0 0 / 100%);

  border: none;
}

.hero__Right {
}

.services {
  margin-top: 50px;
}
.services > h1 {
  text-align: center;
  margin: 0 auto;
  font-size: 29px;
  font-weight: 800 !important;
  max-width: 120px;
  background-image: linear-gradient(120deg, #678bd8 0%, #8fd3f4 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 95%;
  transition: all 0.25s ease-in;
}

.services > h1:hover {
  background-size: 100% 88%;
  color: white;
  cursor: pointer;
}
.services > hr {
  max-width: 80px;
  margin: 10px auto;
}
.services__Info {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}

.services__First > img {
  /* height: 280px; */
  max-width: 340px;
  height: 280px;
  object-fit: cover;
  box-shadow: rgba(199, 197, 197, 0.856) 8px 8px;
}

.services__First {
  text-align: center;
  margin-top: 70px;
}

.services__First > h1 {
  color: #0f74ba;
  margin-top: 20px;
  font-size: 24px;
}

.services__First > p {
  margin-top: 20px;
  font-size: 22px;
  width: 370px;
}

@media screen and (max-width: 1200px) {
  .services__Info {
    flex-direction: column;
  }
  .services__First > p {
    margin: 0 auto;
  }
}

.otherServices {
  display: flex;
  max-width: 1100px;
  margin: 100px auto;
  justify-content: space-evenly;
}

.otherServices__First {
  align-self: center;
}

.otherServices__First > h1 {
  font-size: 29px;
  color: black;
  font-weight: 800;
  margin-bottom: 20px;
  max-width: 125px;
  background-image: linear-gradient(120deg, #678bd8 0%, #8fd3f4 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 95%;
  transition: all 0.25s ease-in;
}

.otherServices__First > h1:hover {
  background-size: 100% 88%;
  color: white;
  cursor: pointer;
}
.otherServices__First > h2 > span {
  color: #0f74ba;
}

.otherServices__First > p {
  margin-top: 20px;
}

.services_Par {
  max-width: 400px;
}

.otherServices__Top > h1 {
  font-size: 20px;

  color: #0f74ba;
  margin: 10px 0;
}
.otherServices__bottom > h1 {
  font-size: 20px;

  color: #0f74ba;
}

.otherServices__Top > p {
  margin: 20px 0;
}

.otherServices__bottom > p {
  margin: 20px 0;
}

@media screen and (max-width: 1200px) {
  .otherServices {
    flex-direction: column;
  }
  .otherServices__Second,
  .otherServices__Third {
    align-self: center;
  }
}

nav {
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  margin-bottom: 15px;
}
nav > img {
  width: 250px;
}

nav > ul {
  cursor: pointer;
  font-size: 22px;
  align-self: center;
  list-style: none;
}

nav > ul > li {
  display: inline;
  margin: 15px;
}

nav > ul > li:hover {
  color: red;
}

.nav-link {
  text-decoration: none;
  color: black;
}

@media screen and (max-width: 730px) {
  nav {
    flex-direction: column;
    align-items: center;
  }
  nav > ul {
    font-size: 15px;
    text-align: center;
  }
}

.Search__result {
  width: 90%;
  max-width: 1100px;
  margin: auto;
}

.back {
  display: flex;
  float: left;
  padding: 5px;
  border-radius: 5px;
  margin-left: 25px;
  opacity: 1;
  color: #5091f9;
  text-decoration: none;
}
.back > h3 {
  margin-top: -3px;
}
.searchResult {
  display: flex;
  position: relative;
  margin: 20px;
  padding: 20px;
  border-bottom: 1px solid lightgray;
  cursor: pointer;
}

.searchResult:hover {
  opacity: 0.8;
}

.search__images {
  height: 200px;
  width: 350px;
  border-radius: 10px;
  overflow: hidden;
}

.searchResult__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 20px;
}

.searchResult__heart {
  position: absolute;
  top: 20px;
  right: 40px;
}

.searchResult__infoTop {
  width: 40vw;
}

.searchResult__infoTop > h3 {
  font-weight: 300;
  margin-top: 10px;
}

.searchResult__infoTop > p {
  margin-top: 10px;
  font-size: 13px;
  color: gray;
}

.searchResult__infoBottom {
  display: flex;
  justify-content: space-between;
}

.searchResult__star {
  color: #5091f9;
}

.searchResult__stars {
  display: flex;
  align-items: center;
}

.searchResults__price > p {
  text-align: right;
}

.searchResults__price {
  position: absolute;
  bottom: 20px;
  right: 30px;
}

.SearchInformation {
  width: 90%;
  max-width: 1100px;
  margin: auto;
}
.SearchInformation__location {
  display: flex;
  justify-content: space-between;
}

.SearchInformation__Left {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.SearchInformation__right {
  display: flex;
  align-items: center;
  grid-gap: 2rem;
  gap: 2rem;
}

.h2 {
  font-size: 25px;
  font-weight: 300;
  margin: 10px;
}

.h2 span {
  font-size: 30px;
}

p {
  font-size: 20px;
}

.SearchInformation__images {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}

.SearchInformation__images > div {
  cursor: pointer;
  height: 230px;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: whitesmoke;
  transition: all 0.5s ease-in;
}

.SearchInformation__images > div:hover {
  opacity: 0.7;
  transform: scale(0.98);
}

.SearchInformation__images > div:nth-of-type(1) {
  grid-column: 1 / 3;
}

.SearchInformation__images > div:nth-of-type(6) {
  grid-column: 3 / 5;
}

.SearchInformation__images > div:nth-of-type(9) {
  grid-column: 3 / 5;
}

.SearchInformation__images > div:nth-of-type(10) {
  grid-column: 1 / 3;
}

.Search__info {
  margin-top: 40px;
  height: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
}
.Search__info div {
  height: 30%;
}
.two {
  width: 40%;
}
.Search__info .Search__info__left,
.Search__info .two {
  height: 45%;
}
.left {
  width: 60%;
}
.Search__info__left {
  display: flex;
  justify-content: space-between;
}
.Search__info__left > img {
  border-radius: 50%;
  height: 50px;
}
.Search__info__container > h3 {
  font-weight: 400;
}

h1 {
  font-weight: 400;
  font-size: 24px;
}

.Search__info__left > h3 {
  border-bottom: 1px solid #333;
}

.entire_home {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.entie_home_text {
  font-size: 0.8rem;
  font-weight: 400;
}
.entie_home_text > p {
  font-size: 1rem;
}

.home {
  margin-top: 20px;
  margin-bottom: -46px;
}

hr {
  opacity: 0.5;
  color: lightgrey;
}
.search__hr {
  margin-top: -35px;
}
.home__hr {
  margin-top: 36px;
}

.covid__19 > p {
  padding-top: 20px;
  font-size: 19px;
}
.covid__19 > button {
  outline: none;
  border: none;
  background-color: #ff6167;
  color: white;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 25px;
}

.covid__19__contct {
  border-bottom: 1px solid #333;
  max-width: 120px;
  cursor: pointer;
}

.sleeping__container {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  text-align: center;
}
.sleeping__container__border {
  border: 1px solid #333;
}
.sleeping {
  margin-top: -70px;
}

.three__container {
  border: 1px solid rgba(61, 59, 59, 0.13);
  padding-bottom: 44vh;
  text-align: center;
}
.three__container > h4 > span {
  color: #ff6167;
}
.three__container > h4 {
  margin-top: 20px;
}
.three__container > img {
  border-radius: 50%;
  height: 70px;
  padding: 20px;
}
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}
form > input {
  margin: 10px;
  max-width: 100%;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.13);

  box-sizing: border-box;
}
.contact__btn {
  background-color: #ff6167;
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
  margin-top: 20px;
}
.emoji {
  color: #ff6167;
}

.searchPage__info {
  padding: 20px;

  width: 90%;
  max-width: 1100px;
  margin: auto;
}

.searchPage__info > p {
  margin-bottom: 10px;
  font-size: 14px;
}

.searchPage__info > h1 {
  margin-bottom: 30px;
}

.searchPage__info > button {
  border-radius: 30px;
  text-transform: inherit;
  margin: 5px;
}

.Link {
  text-decoration: none !important;
}
.Link:visited {
  color: black;
}

.service {
  max-width: 1000px;
  margin: 30px auto;
  display: grid;
  grid-template-rows: [header-start] 100px [header-end box-start] 200px [box-end main-start] 250px [main-end footer-start] 100px [footer-end];
  grid-template-columns: repeat(3, [col-start] 1fr [col-end]) 200px [grid-end];
  grid-gap: 30px;
}
.service > * {
  color: black;
  font-size: 22px;
}
.service .header {
  align-self: end;
  grid-column: col-start 1 / grid-end;
}
.service .sidebar {
  grid-column: col-end 3 / grid-end;
  grid-row: box-start / main-end;
}
.service .main-content {
  grid-column: col-start 1 / col-end 3;
}
.service .footer {
  grid-column: col-start 1 / grid-end;
}
.small__image {
  height: 220px;
  width: 220px;
}
.sidebar__container {
  position: relative;
  display: block;
  box-sizing: border-box;
  width: 300px;
  margin: 50px auto;
  padding: 20px;
  height: 400px;
  border-radius: 5px;
  background: #fdfefe;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.4s ease-in-out;
}
.sidebar__container:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.sidebar__title {
  display: inline-block;
  text-transform: uppercase;
  font-size: 18px;
  padding-bottom: 10px;
  padding-right: 20px;
  color: black;
  border-bottom: 1px solid #423c33;
}

.sidebar__news {
  overflow-y: scroll;
  height: 270px;
  margin-top: 10px;
  padding-right: 10px;
}
.sidebar__item {
  font-size: 19px;
  margin-bottom: 16px;
}
.sidebar__item:last-child {
  margin-bottom: 0;
}
.sidebar__item > p:first-child {
  margin: 5px 0;
}

.sidebar__shares {
  font-family: "Roboto Light", sans-serif;
  color: #dfdfdf;
  font-size: 12px;
  margin: 0;
  cursor: pointer;
}

.sidebar__shares:hover {
  color: #423c33;
}
.coloring {
  color: #5091f9;
  font-weight: 800;
  font-size: 18px;
}

.icon-trending {
  margin-top: -3px;

  margin-right: 5px;
  fill: #5091f9;
  vertical-align: middle;
}
.underline {
  width: 40px;
  margin-top: 5px;
  color: #5091f9;
  border: 1px solid;
}
.underline__middle {
  opacity: 0.5;
  width: 80px;
  margin: 0 auto;
  margin-bottom: 40px;
  margin-top: 20px;
  color: lightgray;
  border: 1px solid;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px;
}

@media screen and (max-width: 1100px) {
  .searchPage__info {
    text-align: center;
  }
  .searchResult__infoTop {
    width: 40vh;
  }
  .searchResult {
    flex-direction: column;
    align-items: center;
  }
  .searchResult__heart {
    display: none !important;
  }
  .searchResults__price {
    position: static;
  }
  .searchResult__infoBottom {
    flex-direction: column;
  }
  .Search__Button {
    padding: 220px;
  }
  .searchResults__price > p {
    text-align: left;
  }
  .h2 {
    font-size: 24px;
    margin: 5px 0 5px 0;
  }
  .search__images {
    width: 250px;
  }
  .searchResult__infoTop > p {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .underline {
    margin: 0 auto;
  }
  .service {
    display: block !important;
    text-align: center;
  }
}

@media screen and (max-width: 1100px) {
}

.course__Title {
  text-align: center;
  margin: 50px auto;
  font-size: 25px;
  max-width: 110px;
  background-image: linear-gradient(120deg, #678bd8 0%, #8fd3f4 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 90%;
  transition: background-size 0.25s ease-in;
}

.course__Title:hover {
  background-size: 100% 88%;
  color: white;
}

/* Courses Page */
.heroCourses {
  justify-content: space-around;
  background-size: contain;
  background-position-y: center;
  background-position-x: 5rem;
  background-attachment: fixed;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 1) 100%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%),
    url("https://images.unsplash.com/photo-1515343480029-43cdfe6b6aae?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1100&q=80");
  height: 500px;
}

.heroCourses__Left {
  max-width: 400px;
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  max-height: 100%;
  padding: 0px 2rem;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
}

.heroCourses__Left > h1 {
  font-size: 24px;
}
.heroCourses__Left > p {
  margin: 10px 0;
}

.heroCourses__Left > button {
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #0f74ba;
  padding: 10px 15px;
  border-radius: 10px;
}
.heroCourses__right > img {
  height: 400px;
}

.coursesSection {
  margin-top: 20px;
}
.coursesSection > h1 {
  text-align: center;
  margin: 0 auto;
  font-size: 25px;
  max-width: 110px;
  background-image: linear-gradient(120deg, #678bd8 0%, #8fd3f4 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 90%;
  transition: background-size 0.25s ease-in;
}

.coursesSection > h1:hover {
  background-size: 100% 88%;
}

.coursesSection__ListItem {
  margin: 60px 0 60px 0;
  display: flex;
  justify-content: space-evenly;
}

hr {
  opacity: 0.5;
  max-width: 700px;
  margin: 30px auto;
}
.coursesSection__ListItem > img {
  align-self: center;
  height: 100px;
  width: 200px;
}

.coursesSection__ListItem__Desc > h1 {
  font-size: 20px;
}

.coursesSection__ListItem__Desc > p {
  max-width: 400px;
  font-size: 15px;
  margin-top: 5px;
}

.coursesSection__ListItem__Desc > p > strong {
  color: red;
}
.CoursesSection__ListItem__Price {
  margin: 10px 0 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.link {
  padding: 8px 13px;
  border: none;
  font-size: 18px;
  color: white;
  cursor: pointer;
  background-color: #0f74ba;
  outline: none;
  cursor: pointer;
}

.course__Link {
  text-decoration: none;
  color: white;
}

.course__Container {
  display: flex;
  flex-direction: column;
}

.course {
}
.course__Row {
  margin-bottom: 50px;
}
.course__Hero {
  text-align: center;
  color: white;
  min-height: 400px;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.6) 100%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%), no-repeat,
    url(/static/media/course-Hero.55b7266c.jpg) no-repeat;
  background-position: calc(100%) calc(25%);
  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.course__Hero > h1 {
  font-size: 26px;
  padding: 10px;
}

.course__Hero > h2 {
  font-size: 20px;
}

.course__Hero > hr {
  margin: 20px auto;
  max-width: 300px;
}
.course__Hero > button {
  color: white;
  background-color: #0f74ba;
  border: none;
  font-size: 24px;
  outline: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 20px;
}
.course__title {
  margin-top: 70px;
}

.course__title > h1 {
  margin: 40px auto;
  text-align: center;
  font-size: 29px;
  max-width: 340px;
  background-image: linear-gradient(120deg, #678bd8 0%, #8fd3f4 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 90%;
  transition: background-size 0.25s ease-in;
}
.course__title > p {
  /* text-align: center; */
  color: rgb(87, 86, 86);
  max-width: 1000px;
  line-height: 1.6;
  margin: 20px auto;
}

.hero__HR {
  color: white !important;
  opacity: 1 !important;
  width: 300px;
}

.parent {
  text-align: center;
}
.parent > p {
  text-align: left;
}
.parent > ul {
  display: inline-block;
  text-align: start;
  font-size: 20px;
  padding-left: 2rem;
}

.parents {
  text-align: center;
}

.powerPoint__About {
  display: flex;
  justify-content: space-evenly;
}

.parents > ul {
  line-height: 2;
  display: inline-block;
  max-width: 600px;
  text-align: start;
}

.powerPoint__h1 {
  font-size: 18px;
  margin: 5px 0;
  color: grey;
  margin-left: -20px;
}

.parent > ul > li {
  color: grey;
  margin-top: 10px;
}

.business__Hero {
  max-width: 1000px;
  height: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.business__Img {
  margin: 40px 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.business__title {
  font-size: 24px;
  font-weight: 800px;
}

.management__H3 {
  text-align: center;
  margin: 50px auto;
  font-size: 25px;
}

.leadership__qoutation {
  text-align: center;
}

.leadership__qoutationStrong {
  color: #0f74ba;
}

.contact__Courses {
  margin: 0 auto;
}

@media screen and (max-width: 630px) {
  .coursesSection__ListItem {
    flex-direction: column;
  }

  .coursesSection__ListItem__Desc > p,
  h1 {
    margin: 10px 0;
    max-width: none;
    text-align: center;
  }
  .CoursesSection__ListItem__Price > button {
    width: 100px;
    margin: 0 auto;
  }
}

.workshop {
  width: 90%;
  max-width: 980px;
  margin: auto;
}
.workshop__Hero {
  background: url(/static/media/WCW3.89cbde26.jpeg);
  height: 60vh;
  background-size: cover;
  background-position: calc(100%) calc(25%);
}
.course {
}

.workshop > h1 {
  text-align: start;
  margin: 40px 0;
  position: relative;
}

.workshop > h1::before {
  content: "";
  background: #0f74ba;
  width: 50px;
  height: 2px;
  position: absolute;
  bottom: -5px;
}

.workshop__info {
  /* text-align: center; */
  color: black;
}
.workshop__info > ul {
  margin-left: 45px;
}

.workshop__info > ul > li {
  margin: 10px 0;
}

.workshop__Info__More {
  line-height: 1.8;
}

.workshop__Info__Plan {
  margin: 50px 0;
  margin-left: 37px;
  color: black;
}
.workshop__Info__Plan > ul {
}
.workshop__Info__Plan > ul > li {
  margin: 10px 0;
}

.worksop__Hero {
  height: 50vh;
  background-size: cover;
  background-image: url("");
}

.worksop__Hero > h1 {
  text-align: center;
}

/* 
linear-gradient( 
180deg
, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.6) 100% ), linear-gradient(
180deg
, rgba(0, 0, 0, 0.2) 0%, transparent 100%), no-repeat, url("") no-repeat; */

footer {
  margin-top: 70px;
  height: 180px;
  background: #1f2325;
  color: white;
  padding: 10px;
}
.footer__Container {
}
footer > h1 {
  margin: 15px 0 15px 0;
  text-align: center;
}
.footer__Icons {
  display: flex;
  justify-content: center;
}
.footer__Icons > a > img {
  border-radius: 50%;
  margin: 10px;
  height: 40px;
}

.footer__Links {
  margin-top: 5px;
  display: flex;
  justify-content: center;
}

.NavLink {
  text-decoration: none;
  color: inherit;
}
.NavLink > h1 {
  margin: 8px;
  font-size: 18px;
}
.footer__Created > h1 {
  margin: 15px 0 15px 0;
  font-size: 18px;
  text-align: center;
}

