.otherServices {
  display: flex;
  max-width: 1100px;
  margin: 100px auto;
  justify-content: space-evenly;
}

.otherServices__First {
  align-self: center;
}

.otherServices__First > h1 {
  font-size: 29px;
  color: black;
  font-weight: 800;
  margin-bottom: 20px;
  max-width: 125px;
  background-image: linear-gradient(120deg, #678bd8 0%, #8fd3f4 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 95%;
  transition: all 0.25s ease-in;
}

.otherServices__First > h1:hover {
  background-size: 100% 88%;
  color: white;
  cursor: pointer;
}
.otherServices__First > h2 > span {
  color: #0f74ba;
}

.otherServices__First > p {
  margin-top: 20px;
}

.services_Par {
  max-width: 400px;
}

.otherServices__Top > h1 {
  font-size: 20px;

  color: #0f74ba;
  margin: 10px 0;
}
.otherServices__bottom > h1 {
  font-size: 20px;

  color: #0f74ba;
}

.otherServices__Top > p {
  margin: 20px 0;
}

.otherServices__bottom > p {
  margin: 20px 0;
}

@media screen and (max-width: 1200px) {
  .otherServices {
    flex-direction: column;
  }
  .otherServices__Second,
  .otherServices__Third {
    align-self: center;
  }
}
