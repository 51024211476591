.contact-section {
  /* height: 40rem;
  background: url("https://images.unsplash.com/photo-1499159058454-75067059248a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1051&q=80");
  height: 650px; */
}
.contact-section h1 {
  text-align: center;
  color: white;
}

form {
  width: 343px;
  max-height: 600px;
  padding: 30px;
  box-shadow: rgb(0 0 0 / 50%) 0px 3px 3rem;
  overflow: hidden;
  border-radius: 5px;
  display: grid;
  background: white;
}

.form__Btn {
  -webkit-box-align: center;
  align-items: center;
  margin-top: 20px;
  font-size: 1.2rem;
  padding: 1rem;
  border: none;
  background: #0f74ba;
  color: rgb(255, 255, 255);
  transition: all 0.2s ease-out 0s;
}
.contact-section > h1 {
  text-align: center;
  margin: 0 auto;
  font-size: 29px;
  max-width: 170px;
  color: black;
  background-image: linear-gradient(120deg, #678bd8 0%, #8fd3f4 100%);
  background-repeat: no-repeat;
  background-size: 100% 0.2em;
  background-position: 0 95%;
  transition: all 0.25s ease-in;
}

.contact-section > h1:hover {
  background-size: 100% 88%;
  color: white;
  cursor: pointer;
}

input {
  padding: 5px;
  outline: none;
  border-radius: 5px;
  border: none;
  border-bottom: 2px solid grey;
  margin-bottom: 10px;
  letter-spacing: 1px;
  transition: all 0.2s ease-out;

  border-top: none;
  border-right: none;
  border-left: none;
  border-image: initial;
  border-bottom: 2px solid grey;
}

.contact__Container {
  display: flex;
  justify-content: space-evenly;
  padding: 50px;
  letter-spacing: 1px;
}

form > textarea {
  padding: 10px;
  margin: 10px auto;
  height: 112px;
  width: 284px;
  border: 0.5px solid grey;
  border-radius: 3px;
  outline: none;
  letter-spacing: 1px;
}

iframe {
  box-shadow: 0 2px 3rem rgba(0, 0, 0, 0.377);
  width: 600px;
  height: 400px;
  border: none;
}

.map__Bottom {
  margin-top: 20px;
  padding: 15px;
  text-align-last: start;
  box-shadow: rgb(0 0 0 / 25%) 0px 1px 1rem;
}
.map__Bottom > h1 {
  margin: 5px 0;
  font-size: 22px;
  color: rgb(110, 110, 110);
}

@media screen and (max-width: 1200px) {
  .contact__Container {
    flex-direction: column;
    align-items: center;
  }
  .map__Bottom {
    box-shadow: none;
  }
  .map__Bottom > h1 {
    text-align-last: center;
  }
}

@media screen and (max-width: 600px) {
  iframe {
    width: 400px;
  }
}
